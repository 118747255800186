document.addEventListener("DOMContentLoaded", function(){
  
  // HIDE/SHOW menu on scroll
  let el_autohide = document.querySelector('.autohide');
  if(el_autohide){
    let last_scroll_top = 0;
    window.addEventListener('scroll', function() {
      let scroll_top = window.scrollY;
      
      if(scroll_top < last_scroll_top || last_scroll_top <= 80) {
        el_autohide.classList.remove('scrolled-down');
        el_autohide.classList.add('scrolled-up');
      }
      else {
        el_autohide.classList.remove('scrolled-up');
        el_autohide.classList.add('scrolled-down');
      }
      last_scroll_top = scroll_top;
    });
  }

  // 
  const navbartoggler = document.querySelector(".navbar-toggler");
  const navbartogglerMobile = document.querySelector(".navbar-toggler-mobile");
  const mobileMenuWrapperEL = document.querySelector(".mobile-menu");
  navbartoggler.addEventListener("click", () => {
    mobileMenuWrapperEL.classList.toggle("d-none")
  });
  navbartogglerMobile.addEventListener("click", () => {
    mobileMenuWrapperEL.classList.toggle("d-none")
  });
});


