document.addEventListener("DOMContentLoaded", () => {
  const toggleBtn = document.querySelector("[data-toggle-search]");
  const toggleElement = document.querySelector("[data-search]");


  document.addEventListener('click', (event) => {
    if (!(event.composedPath().includes(toggleElement) || event.composedPath().includes(toggleBtn))) {
      toggleElement.classList.add("d-none");
    }
  });
  
  toggleBtn.addEventListener("click", () => {
    toggleElement.classList.toggle("d-none");
    toggleElement.querySelector("input").focus();
  });
});