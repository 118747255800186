import Swiper, { Navigation, Pagination, Thumbs } from "swiper";
import { each } from "lodash";
import 'swiper/css/bundle'
import 'swiper/css/navigation'

Swiper.use([Navigation, Pagination, Thumbs]);

const swiper = document.querySelectorAll(".big-slider .swiper");

each(swiper, (element, index) => {
    // eslint-disable-next-line
    new Swiper(swiper[index], {
        slidesPerView: 1,
        spaceBetween: 30,
        watchOverflow: true,
        loop: true,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
        },
        navigation: {
            nextEl: document.querySelectorAll(".swiper-button-next-outside")[index],
            prevEl: document.querySelectorAll(".swiper-button-prev-outside")[index]
        },
        lazy: {
            loadPrevNext: true
        }
    });
});

const aboutOptions = {
    navigation: {
        nextEl: ".swiper-button-next.swiper-button-next--about",
        prevEl: ".swiper-button-prev.swiper-button-prev--about",
    },
    breakpoints: {
        // when window width is >= 320px
        320: {
            slidesPerView: 1,
            spaceBetween: 30,
            allowTouchMove: true
        },
        560: {
            slidesPerView: 2
        },
        // when window width is >= 480px
        840: {
            slidesPerView: 3
        },
        // when window width is >= 1200px
        1200: {
            slidesPerView: 4,
            spaceBetween: 30,
            allowTouchMove: false
        }
    }
};

// Init about carousel
new Swiper(".about-carousel__main", {
    ...aboutOptions,

});
// Init about shadow carousel 
new Swiper(".about-carousel__shadow", {
    ...aboutOptions
});



const recommendedOptions = {
    navigation: {
        nextEl: ".swiper-button-next.swiper-button-next--recommended",
        prevEl: ".swiper-button-prev.swiper-button-prev--recommended",
    },
    breakpoints: {
        // when window width is >= 320px
        320: {
            slidesPerView: 1,
            spaceBetween: 30,
            allowTouchMove: true
        },
        560: {
            slidesPerView: 2
        },
        // when window width is >= 1200px
        1200: {
            slidesPerView: 3,
            spaceBetween: 30,
            allowTouchMove: false
        }
    }
};

// Init recommended carousel  
new Swiper(".recommended-carousel__main", {
    ...recommendedOptions,
});

// Init recommended shadow carousel
new Swiper(".recommended-carousel__shadow", {
    ...recommendedOptions
});


