import $ from "jquery";
import scrollIntoView from 'scroll-into-view';

window.addEventListener("load", () => {
  $('[data-arezidence-scroll-to-element]').on('click', async (e) => {
    e.preventDefault();
    
    try {
      const element = document.querySelector(e.currentTarget.dataset.arezidenceScrollToElement);
      
      scrollIntoView(element, {
        align: {
          top: 0,
          topOffset: 120
        }
      });
    } catch (e) {
      console.error("[data-arezidence-scroll-to-element]", e)
    }
    
  });
});