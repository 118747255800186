import {uniq, filter, map, some} from "lodash";
import slugify from "slugify";

const filterWrapper = document.querySelector("[data-project-filter]");
const dataWrapper = document.querySelector("[data-more-units]");

const generateFilterButtons = (dispositions) => {
  for (const filter of dispositions) {
    const icon = document.createElement("span");
    const text = document.createElement("span");
    const button = document.createElement("button");

    text.innerHTML = filter;
    icon.className = "ms-3 icon-plus";
    
    button.className = "btn btn-outline-primary me-3 mb-3";
    button.dataset.disposition = slugify(filter, {lower: true});
    button.appendChild(text);
    button.appendChild(icon);
    
    
    filterWrapper.querySelector("div").prepend(button);
  }
}

const unitsFilter = (offers) => {
  const resetButton = document.querySelector("[data-reset]");
  const unitsWrapper = document.querySelector("[data-more-units] tbody");
  const activeFilters = document.querySelectorAll("[data-project-filter] .btn-primary");
  const activeFiltersType = map(activeFilters, (item) => item.dataset.disposition);

  resetButton.addEventListener("click", (even) => {
    even.currentTarget.classList.add("d-none");
    for (const btn of activeFilters) {
      const icon = btn.querySelector("[class*=\" icon-\"], [class^=icon-]");
      btn.classList.add("btn-outline-primary");
      btn.classList.remove("btn-primary");
      
      icon.classList.add("icon-plus");
      icon.classList.remove("icon-close");
    }
    for (const offer of offers) {
      unitsWrapper.appendChild(offer);
    }
  });
  
  // show reset button 
  if (activeFilters.length) {
    resetButton.classList.remove("d-none");
  } else {
    resetButton.classList.add("d-none");
  }
  
  if (activeFiltersType.length > 0) {
    let filteredUnits = filter(offers, (offer) => {
      const offerDisposition = slugify(offer.querySelector("[data-disposition]").dataset.dispositionType, {lower: true});
      
      if (activeFiltersType.indexOf(offerDisposition) > -1) {
        return offer
      }
    });
    
    unitsWrapper.innerHTML = "";
    for (const offer of filteredUnits) {
      unitsWrapper.appendChild(offer);
    }
  } else {
    for (const offer of offers) {
      unitsWrapper.appendChild(offer);
    }
  }
}


if (filterWrapper && dataWrapper) {
  const allOffers = document.querySelectorAll("[data-more-units] table tbody tr");
  const allDisposition = document.querySelectorAll("[data-more-units] table tbody [data-disposition]");
  const getAllDisposition = uniq(map(allDisposition, (item) => item.innerHTML.trim())).sort();
  
  generateFilterButtons(getAllDisposition);
  
  const filterButtons = document.querySelectorAll("[data-project-filter] button:not([data-reset])");
  
  // bind fitter handler
  map(filterButtons, (item) => {
    item.addEventListener("click", (event) => {
      const active = event.currentTarget.classList.contains("btn-primary");
      const icon = event.currentTarget.querySelector("[class*=\" icon-\"], [class^=icon-]");
      
      if (active) {
        event.currentTarget.classList.remove("btn-primary");
        event.currentTarget.classList.add("btn-outline-primary");
        icon.classList.remove("icon-close");
        icon.classList.add("icon-plus");
      } else {
        event.currentTarget.classList.remove("btn-outline-primary");
        event.currentTarget.classList.add("btn-primary");
        icon.classList.remove("icon-plus");
        icon.classList.add("icon-close");
      }
      unitsFilter(allOffers)
    })
  });
}