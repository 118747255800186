import $ from "jquery";
window.jQuery = window.$ = $;

require("@fancyapps/fancybox");

// eslint-disable-next-line
const galleryButtonsWrapper = $('.gallery__buttons');
const galleryName = "gallery";

$(document).ready(() => {
    $("a[data-gallery]").fancybox({
        autoFocus: false,
        animationEffect: "fade",
        image: {
            preload: true
        },
        idleTime: 3,
        video: {
            autoStart: false
        },
        transitionEffect: "fade",
        toolbar: true,
    }).attr("data-fancybox", galleryName);

    // Video
    $("a[data-video]").fancybox({
        transitionEffect: "fade",
    });
    
    // Toggle gallery from carousel
    // $(".offer-detail-gallery-carousel .offer-detail-gallery-carousel__item").on("click", (event) => {
    //     $(`.offer-detail-gallery > a:nth-of-type(${event.currentTarget.dataset.fancyboxIndex})`).trigger("click");
    // })
    
    // Toggle gallery
    $("button[data-toggle-gallery]").on("click", () => {
        $(".project-detail__gallery-more-images > a:first-of-type").trigger("click");
    });

    // bottom gallery images
    $(".offer-detail-gallery-bottom .offer-detail-gallery-bottom__item--img").on("click", (event) => {
        event.preventDefault();
        console.log(event.currentTarget.dataset.fancyboxIndex)
        
        $(`.offer-detail-gallery > a:nth-of-type(${event.currentTarget.dataset.fancyboxIndex})`).trigger("click");
    });
    
    
    
});

