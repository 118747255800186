import $ from "jquery";

/**
 * Třída na odesílání formuláře ajaxem
 *
 * @param holder Selector holderu formuláře
 * @constructor
 */
function Form(holder) {
    this.holder = $(holder);
    this.form = $(this.holder.find('form'));
    this.errorsHolder = $(this.form.find('.error-msg'));
    this.submitBtn = this.form.find('[type="submit"]');
    this.isSubmiting = false;
    this.init();
}

Form.prototype = {
    init: function () {
        this.form.on('submit', function (event) {
            if (this.isSubmiting === false) {
                this.submitForm();
            }
            event.preventDefault();
        }.bind(this));
    },

    /**
     * Klasické odeslání formuláře ajaxem
     */
    submitForm: function () {
        this.submitBtn.addClass('is-loading');
        this.isSubmiting = true;

        $.ajax({
            url: this.form.attr('action'),
            method: this.form.attr('method'),
            data: this.form.serialize(),

            success: function (res) {
                this.holder.html(res);
            }.bind(this),

            error: function (res) {
                console.log(res, this.errorsHolder);
                this.errorsHolder.html(res.responseText);
                this.submitBtn.removeClass('is-loading');
            }.bind(this),

            complete: function () {
                this.isSubmiting = false;
            }.bind(this)
        });
    }
};

new Form("#listing_contact_form");
new Form("#mortgage_form_holder");

export default Form;
