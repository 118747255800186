if (document.querySelector(".offer-description__text-inner")) {
    const descriptionSuperWrapper = document.querySelector(".offer-description__text-inner");
    const descriptionSuperWrapperInner = document.querySelector(".offer-description__text-inner-inner");
    const descriptionSuperWrapperOpener = document.querySelector(".offer-description__text-inner-opener");

    document.addEventListener("scroll", () => {
        if (descriptionSuperWrapper.offsetHeight >= descriptionSuperWrapperInner.offsetHeight) {
            descriptionSuperWrapperOpener.style.display = "none";
        }
    });

    descriptionSuperWrapperOpener.addEventListener("click", () => {
        descriptionSuperWrapper.style.maxHeight = "none";
        descriptionSuperWrapperOpener.style.display = "none";
    });
}

const units = document.querySelector("[data-more-units]");
const toggle = document.querySelector("[data-toggle-more-units]");
if (units && toggle) {
    
    if (units.querySelector("table").clientHeight < units.clientHeight) {
        units.style.maxHeight = "none";
        toggle.style.display = "none";
    }

    toggle.querySelector("a").addEventListener("click", (event) => {
        event.preventDefault();

        toggle.style.display = "none";
        units.style.maxHeight = "none";
    });
}
