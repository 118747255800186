/* global Loader, SMap, JAK */
import { debounce } from "lodash";

import pin from "../../images/map-pin.svg";

const initMap = (element, id) => {
    const { gpsLon, gpsLat, address } = element.dataset;
    const options = {
        url: pin,
        anchor: {
            top: 135,
            left: 42
        }
    };

    console.log(gpsLon, gpsLat, address);

    // Exact GPS position
    if (gpsLon && gpsLat) {
        // console.log("GPS");
        const center = SMap.Coords.fromWGS84(gpsLon, gpsLat);
        const map = new SMap(JAK.gel(id), center, 13);
        const layer = new SMap.Layer.Marker();
        const marker = new SMap.Marker(center, "myMarker", options);
        const mapTextElement = document.querySelector(".map__text--gps");

        if (mapTextElement) {
            mapTextElement.classList.add("offer-detail-map__text--show");
        }

        map.addDefaultLayer(SMap.DEF_BASE).enable();
        map.addControl(new SMap.Control.Sync());
        map.addControl(new SMap.Control.Mouse(SMap.MOUSE_PAN | SMap.MOUSE_ZOOM));
        map.addControl(new SMap.Control.Compass(), { top: "50px", right: "10px" });
        map.addControl(new SMap.Control.Zoom({ 2: "Svět", 5: "Stát", 8: "Kraj", 11: "Město", 14: "Obec", 17: "Ulice" }));
        map.addControl(new SMap.Control.ZoomNotification());
        map.addDefaultContextMenu();

        map.addLayer(layer);
        layer.enable();

        layer.addMarker(marker);

        window.addEventListener("resize", debounce(() => {
            map.syncPort();
        }, 500));
        return;
    }

    // Estimated position
    if (address) {
        // console.log("GEO");
        // eslint-disable-next-line no-new
        new SMap.Geocoder(address, (geocoderResponse) => {
            const response = geocoderResponse.getResults()[0].results;
            const mapTextElement = document.querySelector(".map__text--estimated");
            const mapTextElementGPS = document.querySelector(".map__text--gps");
            const mapWrapperElement = document.querySelector(".map");

            if (!response.length && mapWrapperElement) {
                mapWrapperElement.classList.add("offer-detail-map--hidden");
            }
            if (mapTextElement) {
                mapTextElement.classList.add("map__text--show");
            }
            if (mapTextElementGPS) {
                mapTextElementGPS.classList.remove("map__text--show");
            }

            const center = SMap.Coords.fromWGS84(response[0].coords.x, response[0].coords.y);
            const map = new SMap(JAK.gel(id), center, 13);
            const layer = new SMap.Layer.Marker();
            const marker = new SMap.Marker(center, "myMarker", options);

            map.addDefaultLayer(SMap.DEF_BASE).enable();
            map.addControl(new SMap.Control.Sync());
            map.addControl(new SMap.Control.Mouse(SMap.MOUSE_PAN | SMap.MOUSE_ZOOM));
            map.addControl(new SMap.Control.Compass(), { top: "50px", right: "10px" });
            map.addControl(new SMap.Control.Zoom({ 2: "Svět", 5: "Stát", 8: "Kraj", 11: "Město", 14: "Obec", 17: "Ulice" }));
            map.addControl(new SMap.Control.ZoomNotification());
            map.addDefaultContextMenu();

            map.addLayer(layer);
            layer.enable();

            layer.addMarker(marker);

            window.addEventListener("resize", debounce(() => {
                map.syncPort();
            }, 500));
        });
    }
};

// map offer detail
const offerDetailMapSelector = "detail-map";
const offerDetailMapElement = document.querySelector(`#${offerDetailMapSelector}`);

const contactMapSelector = "contact-map";
const contactMapElement = document.querySelector(`#${contactMapSelector}`);

const bindMap = (element, id) => {
    Loader.async = true;
    Loader.load(null, null, () => initMap(element, id));
};

const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
            bindMap(entry.target, entry.target.id);
            observer.unobserve(entry.target);
        }
    });
});

if (offerDetailMapElement) {
    observer.observe(offerDetailMapElement);
}

if (contactMapElement) {
    observer.observe(contactMapElement);
}
