import LazyLoad from "vanilla-lazyload/dist/lazyload";

const myLazyLoad = new LazyLoad({
    elements_selector: ".lazy",
    threshold: 300
});

setTimeout(() => {
    myLazyLoad.update();
}, 500);

export function callLazy() {
    myLazyLoad.update();
}
