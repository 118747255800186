import noUiSlider from "nouislider/dist/nouislider.min";
import "nouislider/dist/nouislider.min.css";
import $ from "jquery";

function formatMoney(amount, decimalCount = 0, decimal = ",", thousands = " ") {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return negativeSign +
            (j ? i.substr(0, j) + thousands : '') +
            i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
            (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e)
    }
}

const priceRange = document.getElementById('customRange1');
const yearRange = document.getElementById('customRange2');
let priceUnit = 'Kč';
let yearUnit = 'let';

let cena = 0;
if (document.getElementById('pevnaCena')) {
    cena = document.getElementById('pevnaCena').getAttribute('data-cena');
    $('#mortgage_contact_form_nemovitost').val($('#pevnyNazev').text());
} else {
    cena = $('#bytCena').val();
    if (cena < 5) {
        cena = 10000000;
    }

    $('#bytCena').on('change', function () {
        let _cena = $('#bytCena').val();
        $('#mortgage_contact_form_nemovitost').val($( "#bytCena option:selected" ).text());
        if (_cena > 5) {
            cena = _cena;
        } else {
            cena = 10000000;
        }
        priceRange.noUiSlider.updateOptions({
            start: cena * 0.2,
            range: {
                'min': 0,
                'max': cena * 0.9
            }
        });

        recountHypoteka();
    });

    $('#mortgage_contact_form_nemovitost').val($( "#bytCena option:selected" ).text());
}
$('#mortgage_contact_form_url').val(document.URL);

if (priceRange && yearRange) {
    priceUnit = document.getElementById('kalkulacka').getAttribute('data-price-unit');
    yearUnit = document.getElementById('kalkulacka').getAttribute('data-year-unit');

    noUiSlider.create(priceRange, {
        start: cena * 0.2,
        step: 10000,
        tooltips: [false],
        range: {
            'min': 0,
            'max': cena * 0.9
        }
    });

    noUiSlider.create(yearRange, {
        start: 20,
        step: 1,
        tooltips: [false],
        range: {
            'min': 1,
            'max': 30
        }
    });
}

/*

Popis výpočtu pro kalkulačku:

doplním ještě vzoreček pro výpočet měsíční splátky a ilustruju ho na příkladu

např. modelová situace:
byt za 10.000.000 Kč,
vlastní zdroje 2.000.000 Kč
doba splácení 30 let
úrok 3.59% za rok

vstupem je:
var kupniCena = 10000000;
var vlastniProstredky = 2000000;
var dobaSplaceni = 30;
var urokovaSazba = 0.0359; //pozor, je potřeba převést % na desetinné číslo

výpočet pak bude:
var p = kupniCena - vlastniProstredky; //půjčka
var i = urokovaSazba/12; //úrok za 1 měsíc
var n = dobaSplaceni*12; //celkový počet měsíčních splátek
var e = Math.pow(1+i, n);
var mesicniSplatka = p*(i*e)/(e-1);

výsledek:
mesicniSplatka —> 38583.4848783514

 */

function recountHypoteka() {
    var kupniCena = cena;
    var vlastniProstredky = priceRange.noUiSlider.get(); // todo - toto taky nemít staticky
    var dobaSplaceni = yearRange.noUiSlider.get();
    var urokovaSazba = 0.05;

    var citatel = (kupniCena - vlastniProstredky) * ((urokovaSazba / 12));
    var jmenovatel = 1 - (Math.pow((1 + (urokovaSazba / 12)), -dobaSplaceni*12));
    var ret = citatel / jmenovatel;

    $('#vysledekJistina').html(formatMoney(vlastniProstredky));
    $('#vysledekSplatka').html(formatMoney(ret));
    $('#mortgage_contact_form_jistina').val(formatMoney(vlastniProstredky));
    $('#mortgage_contact_form_splatka').val(formatMoney(ret));
    $('#mortgage_contact_form_roky').val(dobaSplaceni);
}

if (priceRange && yearRange) {
    recountHypoteka();

    priceRange.noUiSlider.on('update', function () {
        $('#ownResourcesFmt').html(formatMoney(this.get()) + ' ' + priceUnit);
    });

    yearRange.noUiSlider.on('update', function () {
        var roku = Math.round(this.get());
        $('#mortgageLengthFmt').html(roku + ' ' + yearUnit);
    });

    priceRange.noUiSlider.on('change', function () {
        $('#ownResourcesFmt').html(formatMoney(this.get()) + ' ' + priceUnit);
        recountHypoteka();
    });

    yearRange.noUiSlider.on('change', function () {
        var roku = Math.round(this.get());
        $('#mortgageLengthFmt').html(roku + ' ' + yearUnit);
        recountHypoteka();
    });
}
